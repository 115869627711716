export default [
  {
    company: 'Fabit',
    position: 'Team Lead',
    dateRange: 'APR 2022 - Present',
    url: 'https://fabit.ru/',
    description: 'Working on various government & social projects',
    achievements: [
      'Team leading',
      'Repository management',
      'Performance optimization',
      'Interviews',
    ],
  },
  {
    company: 'Grid Dynamics',
    position: 'Team Lead',
    dateRange: 'MAR 2020 - FEB 2022',
    url: 'https://www.griddynamics.com/',
    description: 'Building email sources & delivering optimization',
    achievements: [
      'Team leading',
      'Improvement development flow',
      'Performance optimization',
      'Automated Visual Regression Testing',
    ],
  },
  {
    company: 'Qwil Messenger',
    position: 'Engineer',
    dateRange: 'JUN 2020 - JAN 2021',
    url: 'https://qwil.io/',
    description: 'Developing complex invitation tool with onboarding process.',
    achievements: [
      'Code review',
      'Improving testing coverage',
      'Organization of meetups',
    ],
  },
  {
    company: 'Code Factory',
    position: 'Fullstack',
    dateRange: 'JUN 2020 - JAN 2021',
    url: 'https://codefactorygroup.com/',
    description:
      'Developing a SportsBetting platform for the US market and back-office dashboard for internal use. Implemented RBAC/permissions features from scratch.',
    achievements: ['Team lead', 'Code review', 'Monorepo management'],
  },
  {
    company: 'Endava',
    position: 'Team lead',
    dateRange: 'JUN 2018 - June 2020',
    url: 'https://www.endava.com/',
    description:
      'Development of a rich money exchange platform (Travelex) combining complex UI & business logic. The application makes use of KYC, sending money abroad, withdrawal, internal back-office management.',
    achievements: [
      'Team leading',
      'Coaching',
      'Code review',
      'Migration from angular.js',
    ],
  },
  {
    company: 'Global Upwork',
    position: 'Fullstack',
    dateRange: 'MAR 2013 - Present',
    url: 'https://www.upwork.com/',
    description:
      'Working on various custom projects. Building an impressive reputation on Upwork. Proven history of success with multiple clients.',
    achievements: ['Top rated', 'Rising talent'],
  },
];
